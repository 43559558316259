// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-vigneron-template-js": () => import("./../../../src/components/vigneronTemplate.js" /* webpackChunkName: "component---src-components-vigneron-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carte-js": () => import("./../../../src/pages/carte.js" /* webpackChunkName: "component---src-pages-carte-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politique-js": () => import("./../../../src/pages/politique.js" /* webpackChunkName: "component---src-pages-politique-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-vignerons-js": () => import("./../../../src/pages/vignerons.js" /* webpackChunkName: "component---src-pages-vignerons-js" */)
}

